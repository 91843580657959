import Highway from '@dogstudio/highway'
import DefaultRenderer from './default-renderer'
import DefaultTransition from './default-transition'
import FrontTransition from './front-transition'

export default class Router {
  constructor() {
    this.router = new Highway.Core({
      renderers: {
        default: DefaultRenderer,
        front: DefaultRenderer,
      },
      transitions: {
        default: DefaultTransition,
        front: FrontTransition,
      }
    })
  }

  on(type, func) {
    this.router.on(type, func)
  }

  attach(nodelist) {
    this.router.attach(nodelist)
  }
}
