import Highway from '@dogstudio/highway'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import Page from '../components/page'

export default class DefaultTransition extends Highway.Transition {
  in({ from, to, _, done }) {
    window.scrollTo(0, 0)
    from.remove()

    Page.intro(to, () => {
      enableBodyScroll(document.querySelector('.header'))
      done()
    })
  }

  out({ from, _, done }) {
    document.body.classList.add('is-loading')
    disableBodyScroll(document.querySelector('.header'), {
      reserveScrollBarGap: true
    })

    Page.outro(from, done)
  }
}
