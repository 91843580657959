import anime from 'animejs/lib/anime.es.js'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

export default class Navigation {
  constructor(el, toggle) {
    this.el = el
    this.openBtn = toggle
    this.closeBtn = this.el.querySelector('.navigation__close')
    this.logoParts = this.el.querySelectorAll('.logo__part')

    this.init()
  }

  addListeners() {
    this.openBtn.addEventListener('click', this.open.bind(this))
    this.closeBtn.addEventListener('click', this.close.bind(this))
    window.addEventListener('navigate:out', this.close.bind(this))
  }

  open() {
    disableBodyScroll(this.el, { reserveScrollBarGap: true })

    const timelineIn = anime.timeline({
      easing: 'easeInOutExpo',
    })

    timelineIn.add({
      targets: this.el,
      translateY: ['100%', '0%'],
      duration: 600,
      begin: () => {
        this.el.classList.add('is-visible')
      }
    })

    timelineIn.add({
      targets: '.navigation__item',
      opacity: [0, 1],
      translateY: [25, 0],
      duration: 400,
    }, 400)

    timelineIn.add({
      targets: this.closeBtn,
      opacity: [0, 1],
      rotate: [-45, 0],
      scale: [0.5, 1],
      duration: 400,
    }, 500)

    timelineIn.add({
      targets: [this.logoParts[0], this.logoParts[1]],
      opacity: [0, 1],
      scale: [0.99, 1],
      duration: 400,
      delay: anime.stagger(200),
    }, 600)

    timelineIn.add({
      targets: [this.logoParts[2], this.logoParts[3]],
      opacity: [0, 1],
      scale: [0.99, 1],
      duration: 400,
      delay: anime.stagger(200, { delay: 200 }),
    })
  }

  close() {
    enableBodyScroll(this.el)

    const timelineOut = anime.timeline({
      easing: 'easeInOutCirc',
    })

    timelineOut.add({
      targets: this.closeBtn,
      opacity: 0,
      rotate: 45,
      scale: 0.5,
      duration: 400,
    })

    timelineOut.add({
      targets: this.logoParts,
      opacity: 0,
      duration: 400
    }, 200)

    timelineOut.add({
      targets: '.navigation__item',
      opacity: 0,
      translateY: 25,
      duration: 400,
    }, 200)

    timelineOut.add({
      targets: this.el,
      translateY: '100%',
      duration: 600,
      complete: () => {
        this.el.classList.remove('is-visible')
      }
    }, 300)
  }

  init() {
    this.addListeners()
  }
}
