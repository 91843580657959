import 'lazysizes'
import Router from './global/router'
import Front from './components/front'
import Page from './components/page'
import Navigation from './components/navigation'
import loadComponents from './global/loadComponents'

class Site {
  constructor() {
    this.router = new Router()
    this.links = document.querySelectorAll('[data-navigation]')

    this.init()
  }

  intro() {
    if (window.location.pathname === '/') {
      Front.intro(document.body)
    } else {
      Page.intro(document.body)
    }
  }

  setupNavigation() {
    new Navigation(
      document.querySelector('.navigation'),
      document.querySelector('.header__toggle'),
    )
  }

  setupRouter() {
    this.router.on('NAVIGATE_OUT', () => {
      window.dispatchEvent(new Event('navigate:out'))
    })

    this.router.on('NAVIGATE_IN', ({ to, location }) => {
      for (let i = 0; i < this.links.length; i++) {
        const link = this.links[i]

        link.classList.remove('is-active')

        if (link.href === location.href) {
          link.classList.add('is-active')
        }
      }
    })
  }

  loadComponents() {
    loadComponents(document.body)
  }

  init() {
    this.setupNavigation()
    this.setupRouter()
    this.intro()
  }
}

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual'
}

window.addEventListener('load', () => {
  window.scrollTo(0, 0)
  new Site()
})
