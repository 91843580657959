import anime from 'animejs/lib/anime.es.js'

export default class Front {
  constructor(el) {
    this.el = el

    this.init()
  }

  static intro(el, callback = () => {}) {
    const timeline = anime.timeline()
    const logoParts = el.querySelectorAll('.hero .logo__part')

    timeline.add({
      targets: el,
      opacity: [0, 1],
      duration: 600,
      begin: () => {
        document.body.classList.remove('is-hidden')
        document.body.classList.remove('is-loading')
      },
      complete: callback,
    })

    timeline.add({
      targets: [logoParts[0], logoParts[1]],
      opacity: [0, 1],
      scale: [0.99, 1],
      duration: 600,
      easing: 'easeInOutExpo',
      delay: anime.stagger(200),
    })

    timeline.add({
      targets: [logoParts[2], logoParts[3]],
      opacity: [0, 1],
      scale: [0.99, 1],
      duration: 600,
      easing: 'easeInOutExpo',
      delay: anime.stagger(200, { delay: 200 }),
    })
  }

  static outro(el, callback) {
    anime({
      targets: el,
      opacity: 0,
      easing: 'easeInOutCirc',
      duration: 400,
      complete: callback,
    })
  }

  init() {
    this.intro(this.el)
  }
}
