import anime from 'animejs/lib/anime.es.js'

export default class Page {
  constructor(el) {
    this.el = el
    this.init()
  }

  static intro(el, callback = () => {}) {
    anime({
      targets: el,
      opacity: [0, 1],
      duration: 600,
      begin: () => {
        document.body.classList.remove('is-hidden')
        document.body.classList.remove('is-loading')
      },
      complete: callback,
    })
  }

  static outro(el, callback) {
    anime({
      targets: el,
      opacity: 0,
      easing: 'easeInOutCirc',
      duration: 400,
      complete: callback,
    })
  }

  init() {
    this.intro(this.el)
  }
}
