import Highway from '@dogstudio/highway'
import Front from '../components/front'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

export default class FrontTransition extends Highway.Transition {
  in({ from, to, _, done}) {
    window.scrollTo(0, 0)
    from.remove()

    Front.intro(to, done)
    enableBodyScroll(document.querySelector('.header'))
  }

  out({ from, _, done }) {
    document.body.classList.add('is-loading')
    disableBodyScroll(document.querySelector('.header'), {
      reserveScrollBarGap: true,
    })

    Front.outro(from, done)
  }
}
